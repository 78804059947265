import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyB2Ab11xpvg9FOJUnq12Z7MCGETWDuBZ90",
    authDomain: "heidekraut-45e10.firebaseapp.com",
    databaseURL: "https://heidekraut-45e10.firebaseio.com",
    projectId: "heidekraut-45e10",
    storageBucket: "heidekraut-45e10.appspot.com",
    messagingSenderId: "915556414815",
    appId: "1:915556414815:web:77469a74d5a96821cbf2ea"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);