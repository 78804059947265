<template>
    <div>
        <template v-if="loading">
            <h3>
                Die Seite wird geladen.<br><br>
            </h3>
            <h5>
                Bitte haben Sie einen Augenblick Geduld.
            </h5>
        </template>
        <template v-else-if="!stueck.name || ( ! hasOpenTermin() && ausverkauft.length === 0 && abgesagt.length === 0 && !stueck.pasing )">
            <h4>
                Aktuell können leider noch keine Tickets reserviert werden.<br><br>
            </h4>
            <p>
                Unsere nächsten Produktionen laufen schon. Schauen Sie <br>
                also später wieder rein, um nichts zu verpassen.
            </p>

        </template>
        <template v-else>
            <h3>
                Reservierung für:<br>
                <b><cite>{{stueck.name}}</cite></b>
            </h3>
            <br><br>
            <div>
                <v-row>
                  <v-col cols="12" lg="3">
                    <v-btn color="primary"
                           to="/was_ist"
                           style="color: white !important;">Details zum Stück</v-btn>
                  </v-col>
                  <v-col cols="12" lg="3">
                    <HygieneregelnDialog></HygieneregelnDialog>
                  </v-col>
                </v-row>

                <v-divider class="mt-5 mb-5"></v-divider>

                <v-row>

                    <v-col sm="12" md="11" lg="5" v-if="hasOpenTermin()">
                        <v-card>
                            <v-form ref="contactForm1" class="ajax_form1"
                                    name="contactForm1"
                                    @submit.prevent="submitReservierung">
                                <v-card-text>

                                    <v-text-field dense filled
                                                  validate-on-blur
                                                  :rules="[
                                                      v => !!v || 'Name wird benötigt',
                                                      ]"
                                                  label="Ihr Name:" name="yourname1" v-model="name" id="name1"/>

                                    <v-text-field dense filled
                                                  validate-on-blur
                                                  :rules="[
                                                      v => !!v || 'Mail wird benötigt',
                                                      v => /^\w[\w|\.|\-]+@\w[\w|\.|\-]+\.[a-zA-Z]{2,4}$/.test(v) || 'Keine E-Mail'
                                                      ]"
                                                  label="Ihre E-Mail Adresse:" name="email1" v-model="mail"
                                                  id="email1"/>

                                    <div class="clear"></div>

                                    <v-select attach :menu-props="{'offset-y': true}" dense filled name="Termin1"
                                              :rules="[v => !!v || 'Termin wird benötigt']"
                                              id="Termin1"
                                              label="Veranstaltung"
                                              v-model="termin"
                                              :items="verfuegbar"
                                              :item-text="formatTermin"
                                              :item-value="formatTermin">
                                    </v-select>

                                    <v-select attach :menu-props="{'offset-y': true}" dense filled
                                              :rules="[v => !!v || 'Menge wird benötigt']"
                                              v-model="stueckzahl" :items="[1,2,3,4,5,6,7,8,9,10]"
                                              label="Menge" name="Menge1" id="Menge1">
                                    </v-select>

                                    <br>
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td>Preise</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Normal</td>
                                            <td>15&euro;</td>
                                        </tr>
                                        <tr>
                                            <td>Erm&auml;ßigt</td>
                                            <td>12&euro;</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br>
                                    <p class="body-1"><b>Hinweis:</b>
                                        <br>
                                        Platzreservierungen können nicht vorgenommen werden.<br>
                                      Bitte beachten Sie das <a style="text-decoration: underline;" @click="$vuetify.goTo(0)">Hygienekonzept</a>.
                                    </p>
                                    <div class="clear"></div>

                                    <v-textarea
                                            dense
                                            filled
                                            label="Bemerkungen:"
                                            id="message1" name="message1"
                                            v-model="bemerkung"
                                            no-resize
                                            cols="10"
                                            rows="5"></v-textarea>
                                  <v-checkbox
                                      dense
                                      label="Newsletter abonnieren"
                                      id="newsletter1"
                                      name="newsletter1"
                                      v-model="newsletter"
                                  ></v-checkbox>


                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn small text
                                               @click.stop="reset">Zur&uuml;cksetzen
                                        </v-btn>
                                        <v-btn small name="Send"
                                               id="send1" type="submit">Reservieren
                                        </v-btn>
                                    </v-card-actions>
                                </v-card-text>
                            </v-form>
                        </v-card>
                    </v-col>

                    <v-col sm="12" md="11" lg="5">
                        <template v-if="ausverkauft.length > 0">

                            <div>
                                <h3>Folgende Termine sind ausverkauft:</h3>
                                <br>
                                <template v-for="termin in ausverkauft">
                                    <b :key="termin.date.seconds + 'ausverkauft'" class="mb-3">{{formatTermin(termin)}}
                                    </b>
                                </template>
                                <p style="margin-top: 1rem">Mit etwas Glück können an der Abendkasse
                                    noch Tickets erworben werden, falls kurzfristig Plätze frei werden. Dies kann
                                    jedoch
                                    nicht
                                    garantiert
                                    werden.</p>
                            </div>
                        </template>

                        <template v-if="abgesagt.length > 0">

                          <div>
                            <h3>Folgende Termine mussten leider abgesagt werden:</h3>
                            <br>
                            <template v-for="termin in abgesagt">
                              <b :key="termin.date.seconds + 'abgesagt'" class="mb-3">{{formatTermin(termin)}}
                                -
                                Soundcafe</b>
                            </template>
                            <p style="margin-top: 1rem">
                              Sollten Sie bereits Tickets für diese Termine reserviert haben,
                              bitten wir Sie auf einen anderen Termin auszuweichen.
                            </p>
                          </div>
                        </template>

                      <div>
                        <h3>Auff&uuml;hrungen im Forum2:</h3>
                        <br>
                        <p class="body-1">
                          Hier können nur Karten für die Vorstellungen im Soundcafe oder Giesinger Banhof reserviert werden.<br>
                          Karten für die Vorstellungen im Kultur Forum2 können<br>
                          über die Website vom Forum2 reserviert werden:<br><br>
                          <v-btn color="primary"
                                 target="_blank"
                                 href="https://ticket81.tickethome.at/buchen/419/10.02.2024/1930"
                                 style="color: white !important;">Kartenreservierung Forum2</v-btn>

                          <br><br>
                          <b>Preise (Forum2)</b><br>
                          Normal: 15€<br>
                          Ermäßigt: 12€<br>
                        </p>
                      </div>

                        <template v-if="stueck.pasing">
                            <div>
                                <h3>Auff&uuml;hrungen in der Pasinger Fabrik:</h3>
                                <br>
                                <p class="body-1">
                                    Hier können nur Karten für die Vorstellungen im Soundcafe reserviert werden.<br>
                                    Karten für die Vorstellungen in der Pasinger Fabrik können<br>
                                    über folgenden Telefonnummer reserviert:<br><br>
                                    <a href="tel:+498982929079"><b style="font-size: medium; margin: 20px">089 /82
                                        92 90
                                        79</b></a><br><br>
                                    oder über München Ticket (Preise abweichend) bezogen werden:<br><br>
                                    <v-btn color="primary"
                                           :href="stueck.muenchenTicketLink"
                                           style="color: white !important;">München Ticket</v-btn>

                                    <br><br>
                                    <b>Preise (Pasinger Fabrik)</b><br>
                                    Normal: 16€<br>
                                    Ermäßigt: 12€<br>
                                <p class="body-1">
                                    Bitte beachten Sie, dass beim Kauf von Tickets an
                                    externen Vorverkaufsstellen und
                                    im MünchenTicket-Webshop zusätzlich
                                    Gebühren anfallen können.
                                </p>
                            </div>
                        </template>
                    </v-col>
                </v-row>
            </div>
        </template>
        <v-snackbar multi-line :timeout="snackbarColor === 'success' ? 30000 : 0" v-model="snackbar"
                    :color="snackbarColor">
            {{snackbarText}}
            <v-btn
                    dark
                    text
                    @click="snackbar = false">X
            </v-btn>
        </v-snackbar>
    </div>
</template>

<style>
    .error--text {
        color: #cf160d !important;
    }
</style>

<script>
    import "../../plugins/firebase"
    import firebase from 'firebase/compat/app';
    import 'firebase/compat/firestore';
    import moment from "moment";
    import 'whatwg-fetch';
    import 'promise-polyfill/src/polyfill';
    import HygieneregelnDialog from "@/index/components/HygieneregelnDialog";

    moment.locale('de');

    export default {
        name: "Tickets",
      components: {HygieneregelnDialog},
      metaInfo: {
            title: "Tickets",
            meta: [
                {name: 'description', content: 'Hier können Karte für aktuelle Produktionen reserviert werden'}
            ]
        },
        mounted: function () {
            firebase.firestore().collection('theaterstuecke').doc("aktuell").get().then(value => {
                if(value.data().name) {
                    firebase.firestore().collection('theaterstuecke').doc(value.data().name).get().then(value => {
                        this.stueck = value.data();
                        this.fillAusverkauftAbgesagtVerfuegbar();
                        this.loading = false;
                    })
                } else {
                    this.loading = false;
                }
            })
        },
        data() {
            return {
                loading: true,
                stueck: {
                    name: "",
                    pasing: false,
                    muenchenTicketLink: "",
                    termine: []
                },
                ausverkauft: [],
                abgesagt: [],
                verfuegbar: [],
                name: "",
                mail: "",
                newsletter: false,
                termin: "",
                stueckzahl: 1,
                bemerkung: "",
                snackbar: false,
                snackbarText: "",
                snackbarColor: "success"
            }
        },
        methods: {
            reset() {
                this.$refs.contactForm1.reset()
            },
            submitReservierung() {
                if (this.$refs.contactForm1.validate()) {
                    var formData = new FormData();
                    formData.append('yourname1', this.name);
                    formData.append('email1', this.mail);
                    formData.append('Termin1', this.termin);
                    formData.append('Menge1', this.stueckzahl);
                    formData.append('message1', this.bemerkung);
                    formData.append('newsletter1', this.newsletter);

                    fetch('./sendenquiry.php', {
                        method: 'post',
                        body: formData
                    }).then(result => {
                        if (!result.ok) {
                            throw new Error();
                        }
                        result.json()
                            .then(value => {
                                var message = "Reservierung erfolgreich. Bestätigung wurde verschickt, bitte prüfen Sie Ihren Mail-Eingang (ggfs. Spam-Ordner)";
                                if (value.message !== undefined) {
                                    message = value.message;
                                }
                                this.snackbarColor = "success";
                                this.snackbarText = message;
                                this.snackbar = true;

                                this.reset();
                            })
                            .catch(() => {throw new Error()});

                    }).catch(() => {
                        this.snackbarColor = "error";
                        this.snackbarText = "Reservierung konnte nicht gesendet werden. Bitte melden Sie sich bei uns direkt via tickets@theater-heidekraut.de!";
                        this.snackbar = true;
                    })
                }
            },
            fillAusverkauftAbgesagtVerfuegbar() {
                this.stueck.termine.forEach(termin => {
                    if (this.isFutureTermin(termin)) {
                        if (termin.ausverkauft === true) {
                            this.ausverkauft.push(termin)
                        } else if (termin.abgesagt === true) {
                            this.abgesagt.push(termin)
                        } else {
                            this.verfuegbar.push(termin)
                        }
                    }
                })
            },
            hasOpenTermin() {
                for (var i = 0; i < this.stueck.termine.length; i++) {
                    if (!(this.stueck.termine[i].ausverkauft === true || this.stueck.termine[i].abgesagt === true) &&
                        this.isFutureTermin(this.stueck.termine[i])) {
                        return true;
                    }
                }
                return false;
            },
            isFutureTermin(termin) {
                return moment().isBefore(moment.unix(termin.date.seconds));
            },
            formatTermin(termin) {
                return moment.unix(termin.date.seconds).format('llll');
            }
        }
    }
</script>